<template>
  <b-modal v-model="isShow" ref="" size="md" centered @hidden="hide()">
    <template #modal-header>Change Manufacturer Code</template>
    <b-row>
      <b-col cols="6">
        <InputText
          v-model="form.manufacturer_product_code"
          textFloat="Manufacturer Code"
          placeholder="Manufacturer Code"
        ></InputText>
      </b-col>
      <b-col cols="12">
        <InputTextArea
          v-model="form.remark"
          placeholder="Note"
          rows="4"
          textFloat="Note"
          isRequired
          :v="$v.form.remark"
          :isValidate="$v.form.remark.$error"
        ></InputTextArea>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="hide()" class="border-btn">Cancel</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="submit-btn" @click="save()">Save</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import { required } from "vuelidate/lib/validators";

export default {
  components: { InputTextArea },
  props: {
    // form: {
    //   required: true,
    // },
  },

  data() {
    return {
      isShow: false,
      form: {
        manufacturer_product_code: "",
        remark: "",
      },
    };
  },
  validations: {
    form: {
      remark: { required },
    },
  },
  methods: {
    showModal(item) {
      this.isShow = true;
      this.form = { ...item };
    },
    hide() {
      this.$v.form.$reset();
      this.isShow = false;
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$emit("updateProduct", this.form);
      this.isShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
</style>
