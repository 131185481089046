<template>
  <div>
    <b-modal
      v-if="!isCheck"
      v-model="isShow"
      ref=""
      centered
      @hidden="hide()"
      size="lg"
    >
      <template #modal-header>
        <div class="content-between w-100">
          Create Transfer Form
          <div>
            <font-awesome-icon
              icon="times"
              class="cursor-pointer"
              @click="hide()"
            ></font-awesome-icon>
          </div>
        </div>
      </template>
      <h5 class="text-center text-bold my-2">
        Do you want to create a transfer form?
      </h5>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button @click="save(false)" class="border-btn"
            >Transfer To Main Branch</b-button
          >

          <b-button
            class="submit-btn text-right btn-w"
            @click.prevent="isCheck = true"
            >Create
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal v-else v-model="isShow" ref="" size="xl" centered @hidden="hide()">
      <template #modal-header>Transfer</template>

      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:cell(product_image)="{ item }">
          <div class="position-relative picture-text pic-table">
            <div
              v-if="item.product_image"
              class="pic-box"
              v-bind:style="{
                'background-image': 'url(' + item.product_image + ')',
              }"
            ></div>
            <div v-else>
              <img
                class="pic-box pt-0"
                :src="default_image"
                alt="default Image"
                srcset=""
              />
            </div></div
        ></template>
        <template v-slot:cell(serial)="{ item }">
          {{ item.serial || "-" }}
        </template>
        <template v-slot:cell(quantity_transfered)="{ item }">
          <InputText
            v-if="!item.serial"
            placeholder="จำนวนที่จะโอน"
            class="mb-0"
            textFloat=""
            type="number"
            @blur="handleProductQuantityInput($event, item)"
            @input="(val, e) => limitQuantity(val, e, item)"
            v-model="item.quantity_transfered"
          />
          <div v-else>1</div>
        </template>
        <template v-slot:cell(action)="{ item }">
          <div>
            <b-button
              variant="icon"
              class="px-0"
              @click="deleteProduct(item.po_product_received_id)"
            >
              <b-icon icon="trash-fill"></b-icon>
            </b-button>
          </div>
        </template>
      </b-table>
      <!-- <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      /> -->
      <InputSelect
        v-if="!isMain"
        placeholder="Transfer form"
        title="Transfer form"
        v-model="form.transfer_document_id"
        valueField="document_id"
        textField="document_name"
        isRequired
        :v="$v.form.transfer_document_id"
        :isValidate="$v.form.transfer_document_id.$error"
        :options="listTransferDocument"
      >
        <template v-slot:option-first>
          <b-form-select-option :value="null" disabled
            >-- โอนจากสาขา (TRN) --</b-form-select-option
          >
        </template>
      </InputSelect>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button @click="hide()" class="border-btn btn-w">Cancel</b-button>

          <b-button
            class="submit-btn text-right btn-w"
            @click.prevent="save(true)"
            >Transfer</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    // form: {
    //   required: true,
    // },
  },
  validations: {
    form: {
      transfer_document_id: { required },
    },
  },
  data() {
    return {
      form: { transfer_document_id: null },
      isShow: false,
      isCheck: false,
      isMain: false,
      isBusy: true,
      items: [],
      filter: { po_id: this.$route.params.id, search: "", page: 1, take: 5 },
      fields: [
        { label: "#", key: "index", thStyle: { width: "10%" } },

        { label: "รูปภาพ", key: "product_image", thStyle: { width: "10%" } },

        { label: "รหัสสินค้า", key: "barcode", thStyle: { width: "10%" } },
        {
          label: "ขื่อสินค้า",
          key: "product_name",
          thStyle: { width: "30%" },
        },
        {
          label: "Serial",
          key: "serial",
          thStyle: { width: "10%" },
        },

        { label: "น้ำหนัก", key: "weight", thStyle: { width: "10%" } },
        {
          label: "จำนวน",
          key: "received_quantity",
          thStyle: { width: "10%" },
        },
        {
          label: "จำนวนที่จะโอน",
          key: "quantity_transfered",
          thStyle: { width: "20%" },
        },
        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      id: this.$route.params.id,
      listTransferDocument: [],
      mainBranch: "",
    };
  },

  methods: {
    async showModal(isCheck, mainBranch) {
      this.isCheck = isCheck;
      this.isMain = isCheck;

      const res = await this.axios(`/po/${this.id}/availableTransferProduct`);
      if (res.data.result == 1) {
        if (res.data.detail.length == 0) {
          this.errorAlert("Please received item before transfer product");
          return;
        }
        this.isShow = true;
        this.items = res.data.detail.map((x) => ({
          ...x,
          quantity_transfered: x.received_quantity,
        }));
      } else {
        this.errorAlert(res.data.message);
      }

      this.mainBranch = mainBranch;
      if (!this.isMain) {
        await this.getListTransferDocument();
      } else {
        this.form.transfer_document_id = mainBranch;
      }
      this.isBusy = false;
    },
    async getListTransferDocument() {
      const res = await this.axios.post(
        `/po/GetInventoryTransferDocumentList`,
        { po_id: this.id }
      );
      this.listTransferDocument = res.data.detail;
    },
    hide() {
      this.isShow = false;
    },
    handleProductQuantityInput(event, item) {
      if (!item.quantity_transfered) {
        item.quantity_transfered = 1;
      }
    },
    limitQuantity(val, e, item) {
      if (val == 0) return (e.target.value = 1);
      if (val > item.received_quantity) {
        item.quantity_transfered = item.received_quantity;
        e.target.value = item.received_quantity;
      }
    },
    async deleteProduct(deleteId) {
      this.items = this.items.filter(
        (x) => x.po_product_received_id !== deleteId
      );
    },
    async save(is_createTransfer) {
      if (!this.form.transfer_document_id && !this.isCheck) {
        this.form.transfer_document_id = this.mainBranch;
      }
      this.$v.form.$touch();
      if (this.$v.form.$error && is_createTransfer) {
        return;
      }
      this.confirmAlert({
        message: this.isCheck
          ? "Confirm the transfer of items"
          : "Confirm transfer of item to the main branch ?",
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          this.$bus.$emit("showLoading");
          let payload = {
            po_id: this.id,
            transfer_document_id: this.form.transfer_document_id,
            is_createTransfer: is_createTransfer,
            product: this.items.map((x) => ({
              po_product_received_id: x.po_product_received_id,
              quantity_transfered: x.quantity_transfered,
            })),
          };
          const res = await this.axios.post(`/po/TransferToWarehouse`, payload);
          if (res.data.result === 1) {
            this.successAlert();
            this.$emit("confirmCreate");
            this.hide();
          } else {
            this.errorAlert(res.data.message);
          }
          this.$bus.$emit("hideLoading");
        } else {
          if (!this.isCheck) this.form.transfer_document_id = null;
          this.$v.form.$reset();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}

.btn-w {
  width: 100px;
  text-align: center !important;
}

::v-deep .table-responsive {
  max-height: 350px !important;
}

::v-deep input {
  text-align: center !important;
}
</style>
